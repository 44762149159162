@import "animations";

.App {
	position: relative;
	width: 100vw;
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;
	color: var(--color);
}

.center-overlay {
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

* {
	touch-action: pan-x pan-y;
}

body {
	height: calc(var(--vh, 1vh) * 100);
	overflow: hidden;
	background: var(--background);

	a {
		color: var(--color-selected);
		text-decoration: none;

		&:hover {
			color: var(--color-selected-highlight);
		}

		&:active {
			color: var(--color-selected-deep);
		}
	}
}

button {
	appearance: none;
	box-sizing: border-box;
	border: none;
	outline: none !important;
	background: transparent;
}

button.floaty,
button.free-floating {
	cursor: pointer;
	position: relative;
	z-index: 1;
	display: inline-block;
	font-family: inherit;
	transition: 0.1s $ease-snappy;
	transition-property: background, color;
}

button.floaty {
	padding: 7px 10px;
	border-radius: 8px;
	font-size: 14px;

	@media (prefers-color-scheme: light) {
		color: hsl(180, 1%, 14%);
		background: hsl(0, 0%, 98%);
		box-shadow:
			inset 0 0 1px hsl(0, 0%, 100%),
			0 6px 12px -10px hsla(216, 4%, 26%, 0.8);

		&:not(:disabled):hover {
			background: white;
		}
	
		&:not(:disabled):active {
			background: hsl(0, 0%, 95%);
		}
	}

	@media (prefers-color-scheme: dark) {
		color: hsl(0, 0%, 100%);
		background: hsl(0, 0%, 30%);
		box-shadow:
			inset 0 0 1px hsla(206, 6%, 77%, 0.6),
			0 6px 12px -10px hsla(210, 8%, 5%, 0.8);

		&:not(:disabled):hover {
			background: hsl(0, 0%, 32%);
		}
	
		&:not(:disabled):active {
			background: hsl(0, 0%, 25%);
		}
	}

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
}

button.free-floating {
	position: relative;
	padding: 0.5em 1.2em;
	border-radius: 8px;
	font-size: 15px;
	font-weight: bold;
	background: transparent;

	&:not(:disabled):hover {
		background-color: var(--soft-background-hover);
	}

	&:not(:disabled):active {
		background-color: var(--soft-background-active);
	}
	
	&:not(:disabled).notification::after {
		content: "";
		position: absolute;
		right: 4px;
		top: 4px;
		width: 6px;
		height: 6px;
		background-color: var(--color-alert-danger);
		border-radius: 5px;
	}

	@media (prefers-color-scheme: light) {
		color: hsl(0, 0%, 95%);
		text-shadow:
			0 1px 3px hsla(0, 0%, 0%, 0.3),
			0 1px 2px hsla(0, 0%, 0%, 0.3);

		&:not(:disabled):hover {
			color: white;
		}
	
		&:not(:disabled):active {
			color: hsl(0, 0%, 94%);
		}
		
		&.hot:not(:disabled) {
			color: hsl(34, 100%, 66%);
			animation: hot-pulse-light 3s $ease-in-out-cubic infinite;
			text-shadow:
				0 1px 2px hsla(0, 100%, 16%, 0.6),
				0 0 5px hsla(27, 80%, 8%, 0.3);

			&:hover {
				color: hsl(39, 100%, 71%);
			}
		
			&:active {
				color: hsl(29, 83%, 65%);
			}
		}
	}

	@media (prefers-color-scheme: dark) {
		color: hsl(0, 0%, 75%);
		text-shadow:
			0 1px 3px black,
			0 1px 2px black;

		&:not(:disabled):hover {
			color: hsl(0, 0%, 85%);
		}
	
		&:not(:disabled):active {
			color: hsl(0, 0%, 90%);
		}
		
		&.hot:not(:disabled) {
			color: hsl(24, 100%, 58%);
			animation: hot-pulse-dark 3s $ease-in-out-cubic infinite;

			&:hover {
				color: hsl(29, 100%, 58%);
			}
		
			&:active {
				color: hsl(24, 84%, 52%);
			}
		}
	}

	@keyframes hot-pulse-light {
		from, to {
			background-color: hsla(0, 0%, 0%, 0.12);
			box-shadow: 0 0 30px -3px rgba(255, 155, 5, 0);
		}
		10% {
			background-color: hsla(30, 58%, 44%, 0.7);
			box-shadow: 0 0 30px -3px rgba(255, 155, 5, 0.8);
		}
	}

	@keyframes hot-pulse-dark {
		from, to {
			background-color: hsla(0, 0%, 100%, 0.15);
			box-shadow: 0 0 30px -3px rgba(255, 102, 0, 0);
		}
		10% {
			background-color: hsla(34, 100%, 60%, 0.4);
			box-shadow: 0 0 30px -3px rgba(250, 112, 0, 0.5);
		}
	}

	&.selected {
		color: var(--color-selected) !important;
		background-color: var(--card-background);
		text-shadow: none;

		&:hover {
			background-color: var(--card-background);
		}
	}

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
}

.App.small-ui button.free-floating {
	font-size: 13px;
}