@import "animations";
@import "colors";

.Inspector {
	--inset-padding: 6px;
	--inspector-border-radius: 14px;
	--inspector-padding-v: 13px;
	--inspector-padding-h: 20px;
	padding: 10px 15px 0;
	overflow-y: auto;
	border-radius: var(--inspector-border-radius);

	> .bottom-spacing {
		padding-bottom: 30px;
	}

	> .card-info {
		margin-bottom: 20px;

		> .type {
			font-size: 24px;
			font-weight: bold;
		}

		> .id {
			margin-top: 10px;
			color: var(--color-soft);

			> .label {
				font-size: 15px;
				font-weight: 600;
			}

			> .value {
				display: inline-block;
				margin-top: 5px;
				font-size: 14px;
				padding: 2px 4px;
				border-radius: 6px;
				background: var(--soft-background);
			}
		}
	}

	.floating-field {
		margin-bottom: 20px;

		> .title {
			cursor: default;
			font-size: 18px;

			> .icon {
				margin-right: 0.4em;
			}
		}
	}

	// Any field.
	.field {
		position: relative;
		padding: 0 var(--inspector-padding-h) var(--inspector-padding-v);

		.field + .field {
			padding-top: var(--inspector-padding-v);
		}
		
		// Any label.
		.label,
		> label,
		> .column > label,
		.ActionInput label,
		.field-label {
			cursor: default;
			position: relative;
			display: block;
			color: var(--color);
			z-index: 1;
			margin-top: 13px;
			margin-bottom: 7px;
			box-sizing: border-box;
			font-size: 14px;

			> .field-action {
				position: absolute;
				top: -6px;
				right: calc(-1 * var(--inspector-padding-h) + 9px);
				font-size: 14px;
				padding: 4px 10px;
				cursor: pointer;
			}
		}

		select {
			font-size: 16px;
		}

		.TextInput + .TextInput,
		select + .TextInput {
			margin-top: 6px;
		}

		> .title:first-child {
			padding-top: var(--inspector-padding-v);
		}

		> button:first-child,
		> select:first-child {
			margin-top: var(--inspector-padding-v);
		}

		:not(label) + .columns,
		.columns:first-child {
			margin-top: var(--inspector-padding-v);
		}

		> .hint,
		.columns .hint {
			margin-top: 0.5em;
			color: var(--color-hint);
			font-size: 14px;
			line-height: 1.4em;
		}

		> select {
			font-size: 16px;
			margin: 2px 0 0;
		}

		.CardSelect:not(:first-child) {
			margin-top: 5px;
		}

		> .ImageInput:not(:first-child) {
			margin-top: 12px;
		}

		> .ImageInput:only-child,
		> .CardSelect:only-child {
			margin: 14px auto 0;
		}

		.InspectorListNewItem {
			cursor: pointer;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			background: var(--color-panel-inset);
			box-shadow: var(--inspector-inset-shadow);
			line-height: 1em;
			text-shadow: 0 1px 0 var(--color-contrast-text-shadow);
			color: var(--color-soft);
			margin: var(--inspector-padding-v) var(--inspector-padding-h) calc(var(--inspector-padding-v) / 2);
			border-radius: 100px;
	
			> .title {
				padding: 15px;
				font-size: 18px;
				text-align: center;
			}

			> .GuidingStar {
				position: relative;
				top: -1px;
			}
	
			&:hover {
				color: var(--color-selected);
			}
	
			&:active {
				color: var(--color-selected-deep);
			}
		}
		
		.actions-list {
			display: flex;
			flex-direction: column;
			align-items: center;
			background: var(--color-panel-inset);
			box-shadow: var(--inspector-inset-shadow);
			border-radius: 14px;
			padding: var(--inset-padding);

			> button {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				> .GuidingStar {
					margin-inline-end: 0.25em;
				}
			}
	
			> button + button {
				margin-top: var(--inset-padding);
			}
		}

		> .actions-list,
		.column > .actions-list {
			&:first-child {
				margin-top: var(--inspector-padding-v);
			}
		}

		> pre {
			display: block;
			border-radius: 14px;
			padding: var(--inset-padding);
			height: 200px;
			min-height: 200px;
			max-height: 500px;
			resize: vertical;
			overflow-x: auto;
			overflow-y: auto;
			background: var(--color-panel-inset);
			box-shadow: var(--inspector-inset-shadow);
			font-size: 13px;
			line-height: 1.5em;
			tab-size: 1.5em;
		}
	}

	// Top level field.
	> .field {
		border-radius: inherit;
		background: var(--color-panel-face);
		box-shadow: var(--floating-object-shadow);
		margin-bottom: 15px;

		// Top level label.
		> label:first-child,
		> .label:first-child {
			position: sticky;
			top: -10px;
			z-index: 100;
			padding: var(--inspector-padding-v) var(--inspector-padding-h);
			margin: 0 calc(-1 * var(--inspector-padding-h));
			background: var(--color-panel-face);
			border-top-left-radius: var(--inspector-border-radius);
			border-top-right-radius: var(--inspector-border-radius);
			border-left: 1px solid transparent;
			border-right: 1px solid transparent;
			background-clip: padding-box;
			font-size: 18px;
			font-weight: bold;

			// Top level field action.
			> .field-action {
				display: inline-block;
				pointer-events: auto;
				top: 50%;
				margin-top: -1em;
				right: 8px;
				font-size: 14px;
			}
		}

		&.group {
			padding-left: 0;
			padding-right: 0;

			> label,
			> .label {
				font-size: 24px;
				font-weight: bold;
				margin-left: 0;
				margin-right: 0;
				padding-top: calc(var(--inspector-padding-v) * 1.2);
			}
		}
		
		.TextInput + label {
			display: block;
			margin-top: 10px;
		}
		
	}

	.field-action {
		&:not(:disabled):hover {
			color: var(--color-selected-highlight);
		}

		&:not(:disabled):active {
			color: var(--color-selected-deep);
		}

		@media (prefers-color-scheme: light) {
			color: var(--color-soft);
			text-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.05);
		}
	}

	&.animate-fields > .field,
	&.animate-fields > .RevealableField {
		animation: field-slide-in 0.9s $ease-out-quint 1s backwards;

		$i: 0;
		@while $i < 10 {
			&:nth-child(#{$i + 1}) {
				animation-delay: $i * 0.1s;
			}
			$i: $i + 1;
		}
	}

	> .RevealableField {
		cursor: pointer;
		width: 100%;
		padding: 21px;
		margin-top: 10px;
		border: none;
		border-radius: var(--inspector-border-radius);
		background: var(--soft-background);
		color: var(--color-soft);
		font-size: 18px;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		&:hover {
			background: var(--soft-background-hover);
		}

		&:active {
			background: var(--soft-background-active);
		}
	}

	> .RevealableField + .RevealableField {
		margin-top: 0;
	}

	> .RevealableField + .field {
		margin-top: 10px;
	}

	@media (max-width: 1109px) {
		width: 340px;

		.columns {
			> .column + .column {
				margin-top: 10px;
			}
		}
	}

	@media (min-width: 1110px) {
		width: 550px;

		.columns {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			> .column {
				&.wide {
					flex-grow: 1;
				}

				&:not(.wide) {
					.ImageInput {
						width: 150px;
					}
				}

				&:not(:first-child) {
					margin-left: 30px;
				}
				
				.field-label:first-child {
					margin-top: 0;
				}
			}
		}
		
		.field .actions-list.wide {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			
			> button {
				width: 49%;

				&:not(:nth-child(1n+3)) {
					margin-top: 0;
				}
		
				> button + button {
					margin-top: 0;
				}

				&:nth-child(1n+3) {
					margin-top: var(--inset-padding);
				}
			}
		}
	}
}

.App.large-ui .Inspector {
	min-width: 340px;
}

.App.small-ui .Inspector {
	width: calc(100vw - 24px);
	padding-left: 12px;
	padding-right: 12px;

	> .card-info {
		margin-top: calc(var(--vh, 1vh) * 25);
	}
}

@keyframes field-slide-in {
	from {
		transform: translateY(200px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}